import React, {useEffect, useState} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
);


export function LinearDiagramForTrade({startPrice, delta, amountToBuy, amountToSell, fee, setWillCost, setWillEarn}) {

    const [dataDiagram, setDataDiagram] = useState([])
    const [labels, setLabels] = useState([])


    function LinearCurveForTrade() {
        let price = Number(startPrice) * 100;
        delta *= 100;
        const sellArr = [];
        const buyArr = [];
        for (let i = 1; i <= amountToSell; i++) {
            sellArr.push(price / 100);
            price += delta;
        }
        price = startPrice * 100;
        for (let i = 0; i <= amountToBuy; i++) {
            const priceWithFee = price * (1 - Number(fee)  / 100);
            buyArr.push(priceWithFee / 100);
            price -= delta;
        }


        buyArr.reverse().pop();
        setWillCost(buyArr)
        setWillEarn(sellArr)
        setDataDiagram([...buyArr, ...sellArr]);
    }



    useEffect(() => {
        LinearCurveForTrade();
    }, [startPrice, delta, amountToBuy, amountToSell, setDataDiagram, fee]);

    useEffect(() => {
        const labels = [];

        for (let i = amountToBuy; i >= 1; i--) {
            labels.push(i.toString());
        }

        for (let i = 1; i <= amountToSell; i++) {
            labels.push(i.toString());
        }

        setLabels(labels);
    }, [amountToSell, amountToBuy]);



    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: null,
            },
        },
        scales: {
            y: {
                ticks: {color: '#FFFFFF', beginAtZero: true},
                grid: {
                    display: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    color: '#8E8E8E'
                }
            },
            x: {
                ticks: {color: '#FFFFFF', beginAtZero: true},
                grid: {
                    display: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    color: '#2B282C'
                }
            }
        },
    };


    const data = {
        labels,
        datasets: [
            {
                data: dataDiagram,
                borderColor: 'rgb(255,255,255)',
                backgroundColor: 'rgba(77,50,42,0.54)',
                pointBorderWidth: 7,
                // pointBorderColor: generatePointBackgroundColors,
                pointBackgroundColor: '#E65634',
                fill: true,
                color: '#FFFFFF',
            },
        ],
    };

    return <Line width={927} height={241} options={options} data={data} />;
}
