import React, {useContext, useState, useEffect} from 'react';

import {UALContext} from "ual-reactjs-renderer";

import {
    getBuyPoolsByCollections,
    getSellPoolsByCollections, getTradePoolsByCollections,
} from "../../services/wax.services";

import './AllPools.scss';
import PoolItem from "../../components/PoolItem/PoolItem";
import OrangeBorderButton from "../../components/OrangeBorderButton/OrangeBorderButton";
import Switcher from "../../components/Switcher/Switcher";
import FillterSideBar from "../../components/FilterSideBar/FilterSideBar";

const AllPools = () => {
    const {activeUser} = useContext(UALContext);
    const [sellPools, setSellPools] = useState([]);
    const [searchCollection, setSearchCollection] = useState('')
    const [selectedFilterCollection, setSelectedFilterCollection] = useState('all')
    const [selectedFilterSchema, setSelectedFilterSchema] = useState([])
    const [selectedPoolType, setSelectedPoolType] = useState('')

    const [buyPools, setBuyPools] = useState([]);
    const [tradePools, setTradePools] = useState([]);
    const [checkedEmpty, setCheckedEmpty] = useState(true)
    const [checkedHidden, setCheckedHidden] = useState(true)
    const [openedMobileFilter, setOpenedMobileFilter] = useState(false)

    const [scrollLocked, setScrollLocked] = useState(false);


    // useEffect(() => {
    //     if (openedMobileFilter) {
    //         window.addEventListener('scroll', handleScroll);
    //     } else {
    //         window.removeEventListener('scroll', handleScroll);
    //     }
    //
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [openedMobileFilter]);


    const handleScroll = (event) => {
        if (scrollLocked) {
            event.preventDefault();
            event.stopPropagation();
            window.scrollTo(0, 0);
        }
    };


    useEffect(() => {

        const sellPools = () => {
            getSellPoolsByCollections()
                .then((data) => setSellPools(data))
                .catch((error) => console.log(error))
        }

        const buyPools = () => {
            getBuyPoolsByCollections()
                .then((data) => setBuyPools(data))
                .catch((error) => console.log(error))
        }

        const tradePools = () => {
            getTradePoolsByCollections()
                .then((data) => setTradePools(data))
                .catch((error) => console.log(error))
        }

        sellPools()
        buyPools()
        tradePools()
    }, []);


    const handleOpenMobileFilter = () => {
        setOpenedMobileFilter(!openedMobileFilter);
        setScrollLocked(!openedMobileFilter);
    };

    const handleCheckboxClick = (type) => {
        if (type === selectedPoolType) {
            setSelectedPoolType('');
        } else {
            setSelectedPoolType(type);
        }
    }

    const handleSelectSchema = (schema) => {
        setSelectedFilterSchema(schema)

        sellPools.concat(buyPools, tradePools).find((item) => {
            if (item.schemas.includes(...schema))
                setSelectedFilterCollection(item.NFTs_collection.collection_name)
        })
    }

    function handleSelectedFilterCollection(event) {
        setSelectedFilterCollection(event.target.value)
        setSelectedFilterSchema([])
    }

    return(
        <div className={`all-pools ${scrollLocked ? 'scroll-locked' : ''}`}>
         <div className="scrollable-block">
            <div className={`mobile-filter ${openedMobileFilter ? 'opened' : ''}`}>
                <div className="mobile-filter-top">
                    <OrangeBorderButton width={224} height={64} onClick={() => setOpenedMobileFilter(false)}>Accept</OrangeBorderButton>
                </div>
                <div className="side-bar">
                    <div className="collections">
                        <p>Collections</p>
                        <select value={selectedFilterCollection} onChange={(event) => handleSelectedFilterCollection(event)}>
                            <option value="all">All</option>
                            {sellPools && [...new Set(sellPools.concat(buyPools, tradePools)
                                .map(item => item.NFTs_collection.collection_name))]
                                .map((collectionName) => (
                                    <option key={collectionName} value={collectionName}>{collectionName}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="schemas">
                        <p>Schemas</p>
                        <div className="schemas-list">
                            {sellPools.concat(buyPools, tradePools)
                                .filter((item) => {
                                    if (selectedFilterCollection === 'all') {
                                        return true;
                                    }
                                    return item.NFTs_collection.collection_name === selectedFilterCollection;
                                })
                                .reduce((uniqueSchemas, item) => {
                                    if (!uniqueSchemas.includes(item.schemas)) {
                                        uniqueSchemas.push(item.schemas);
                                    }
                                    return uniqueSchemas;
                                }, [])
                                .map((schema, index) => (
                                    <p key={index} onClick={() => handleSelectSchema(schema)}>
                                        {schema}
                                    </p>
                                ))
                            }
                        </div>
                    </div>
                    <div className="tool-type">
                        <p>Tool type</p>
                        <div className="tool-type-block">
                            <p>NFT</p>
                            <input type="checkbox" checked={selectedPoolType === 'nft'} onChange={() => handleCheckboxClick('nft')} />
                        </div>
                        <div className="tool-type-block">
                            <p>Token</p>
                            <input type="checkbox" checked={selectedPoolType === 'token'} onChange={() => handleCheckboxClick('token')} />
                        </div>
                        <div className="tool-type-block">
                            <p>Trade</p>
                            <input type="checkbox" checked={selectedPoolType === 'trade'} onChange={() => handleCheckboxClick('trade')} />
                        </div>
                    </div>
                </div>
            </div>
         </div>

         <div className={"all-pools_title"}>
             <div className={'all-pools_title-left'}>
                 <h2>Pools</h2>
                 <div className="hiding">
                     <Switcher checked={checkedHidden} setChecked={setCheckedHidden} label={checkedHidden ? 'Hiding empty pool' : 'Showing empty pools'} />
                 </div>
             </div>
             <div className="create-new">
                 <OrangeBorderButton width={179} height={44} link={'/create'}>+ Create New Pool</OrangeBorderButton>
             </div>
             <div className="filter-button">
                 <OrangeBorderButton onClick={() => handleOpenMobileFilter()} width={179} height={44}>Filter</OrangeBorderButton>
             </div>
         </div>
            <div className="all-pools-content">
                <FillterSideBar
                    sellPools={sellPools}
                    buyPools={buyPools}
                    tradePools={tradePools}
                    checked={checkedEmpty}
                    setChecked={setCheckedEmpty}
                    setSearchCollection={setSearchCollection}
                    selectedFilterCollection={selectedFilterCollection}
                    setSelectedFilterCollection={setSelectedFilterCollection}
                    setSelectedFilterSchema={setSelectedFilterSchema}
                    selectedPoolType={selectedPoolType}
                    setSelectedPoolType={setSelectedPoolType}
                />
                <div className={"all-pools_list"}>
                    <PoolItem
                        filteredSellPools={
                            selectedPoolType === 'nft' ? sellPools : (selectedPoolType === 'token' ? [] : (selectedPoolType === 'trade' ? [] : sellPools))
                        }
                        filteredBuyPools={
                            selectedPoolType === 'token' ? buyPools : (selectedPoolType === 'nft' ? [] : (selectedPoolType === 'trade' ? [] : buyPools))
                        }
                        filteredTradePools={
                            selectedPoolType === 'trade' ? tradePools : (selectedPoolType === 'nft' || selectedPoolType === 'token' ? [] : tradePools)
                        }
                        checked={checkedHidden}
                        searchCollection={searchCollection}
                        selectedFilterCollection={selectedFilterCollection}
                        selectedFilterSchema={selectedFilterSchema}
                        selectedPoolType={selectedPoolType}
                    />
                </div>
            </div>
        </div>
    )
};

export default AllPools;
