import React from 'react';

import 'react-alice-carousel/lib/alice-carousel.css';


import StakeIcon from "../images/stake.png";
import FeeIcon from "../images/fee.png";
import SecureIcon from "../images/secure.png";
import CenterComponentIcon from "../images/Component 11 – 1.png";
import WaxIcon from "../images/Wax big.png"
import NFTIcon from "../images/NFT.png"
import NextIcon from "../images/next.png"


import './MainPage.scss';


import {DiscordIcon} from "../images/SVGElements/DiscordIcon";
import {TwitterIcon} from "../images/SVGElements/TwitterIcon";
import {MediumSocialSVG} from "../images/SVGElements/MediumIcon";

function MainPage() {


    const AboutItem = ({ icon, text }) => (
        <div className="about_item">
            <img src={icon} alt="" />
            <p>{text}</p>
        </div>
    );

    const SelectOptionItem = ({ text, firstImg, secondImg }) => (
            <div className="select-options_item">
                <h4>{text}</h4>
                <div className="content">
                    <div className="first-icon">
                        <img src={firstImg} alt=""/>
                    </div>
                    <div className="middle-icon">
                        <img src={NextIcon} alt=""/>
                    </div>
                    <div className="last-icon">
                        <img src={secondImg} alt=""/>
                    </div>
                </div>
            </div>
    );

    const SelectOptionItemBoth = ({ text }) => (
        <div className="select-options_item_both">
            <h4>{text}</h4>
            <div className="content">
                <div className="colab">
                    <div className="first-icon">
                        <img src={WaxIcon} alt=""/>
                    </div>
                    <div className="second-icon">
                        <img src={NFTIcon} alt=""/>
                    </div>
                </div>
                <div className="middle-icon">
                    <img src={NextIcon} alt=""/>
                </div>
                <div className="procent">
                    <p>%</p>
                </div>
            </div>
        </div>
    );


    return (
        <>
            <div className="description">
                <h1>
                    Automated Market Maker <br/>for <span>NFTs on WAX</span>
                </h1>
                <div className="about">
                    <AboutItem icon={StakeIcon} text="Earn on arbitrage" />
                    <AboutItem icon={FeeIcon} text="Just 1% Fees" />
                    <AboutItem icon={SecureIcon} text="Fully on-chain" />
                </div>
            </div>
            {/*<div className="airdrop-button">*/}
            {/*    <OrangeBorderButton link={'https://bblk.io/QizT'} width={297} height={76}>Join Airdrop</OrangeBorderButton>*/}
            {/*</div>*/}
            <div className="select-options">
                <SelectOptionItem text="Buy NFTs with tokens" firstImg={WaxIcon} secondImg={NFTIcon} />
                <SelectOptionItem text="Sell NFTs with tokens" firstImg={NFTIcon} secondImg={WaxIcon} />
                <SelectOptionItemBoth text="Do both and earn trading fees" />
            </div>
            <div className="benefits">
                <div className="benefits-center-icon">
                    <img src={CenterComponentIcon} alt="" />
                </div>
                <div className="benefits-left">
                    <h3>Benefits for Users:</h3>
                    <p>You can buy a precise collection</p>
                    <p>An exact quantity of NFTs<br /> can be offered for sale</p>
                    <p>Bulk buy and sell</p>
                </div>
                <div className="benefits-right">
                    <h3>Benefits for Collections:</h3>
                    <p>AMM: you can keep price and<br/> growth of the NFT ecosystem</p>
                    <p>You can setup rules to grow your<br/> floor price</p>
                </div>
            </div>

            <div className="tokenomics-block">
                <div className="tokenomics-block_title">
                    <h2>Tokenomics</h2>
                    <p>Total supply: 130 Million ALCH</p>
                </div>
                <div className="tokenomics-block_content">
                    <div className="tokenomics-block_content-diagram">
                        <div className="first"><p>20M</p></div>
                        <div className="second"><p>20M</p></div>
                        <div className="third"><p>90M</p></div>
                    </div>
                    <div className="tokenomics-block_content-subtext">
                        <div className="first-p">
                            <div className="color-div"></div>
                            <p>20 million ALCH - team tokens (12 months vesting, monthly unlocks)</p>
                        </div>
                        <div className="second-p">
                            <div className="color-div"></div>
                            <p>20 million ALCH - liquidity and staking</p>
                        </div>
                        <div className="third-p">
                            <div className="color-div"></div>
                            <p>90 million ALCH - trading fees rewards</p>
                        </div>
                    </div>
                </div>
                <div className="diagram">
                    <div className="diagram-img">
                        <div className="diagram-text">
                            <p>Distribution of ALCH for every WAX paid in fees</p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="footer">
                <a href="https://dapplica.io/">Dapplica, 2023</a>
                {/*<OrangeBorderButton>Join Airdrop</OrangeBorderButton>*/}
                <div className="social">
                    <div className="icon-discord">
                        <a href="https://discord.gg/Ndx9Tfphfs"><DiscordIcon /></a>
                    </div>
                    <div className="icon-twitter">
                        <a href="https://twitter.com/AlchemySwap">
                            <TwitterIcon />
                        </a>
                    </div>
                    <div className="icon-medium">
                        <a href="https://medium.com/@alchemyswap">
                            <MediumSocialSVG />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MainPage;
