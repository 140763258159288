import React, {useEffect, useState} from 'react';

import { useSelector} from "react-redux";
import FilteredTable from '../../components/FilteredTable/FilteredTable';
import LoaderData from '../../components/LoaderData/LoaderData';

import {
    getBuyPoolsByCollections,
    getSellPoolsByCollections,
    getTradePoolsByCollections
} from "../../services/wax.services";

import './CollectionsPage.scss';



const CollectionsPage = () => {
    const allCollections = useSelector(state => state.allCollections)
    const [allPools, setAllPools] = useState([]);

    useEffect(() => {
        Promise.all([getBuyPoolsByCollections(), getSellPoolsByCollections(), getTradePoolsByCollections()])
            .then(([buyData, sellData,tradeData]) => {
                const combinedData = [...buyData, ...sellData, ...tradeData];
                setAllPools(combinedData);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);


    return (
        <>
            <div className="collections-page-title">
                <h2>Collections</h2>
            </div>
            <div className="collections-page-table">
                {!allCollections.length ? (
                    <LoaderData />
                ) : (
                    <FilteredTable allCollections={allCollections} allPools={allPools} />
                )}
            </div>
        </>
    );
};

export default CollectionsPage;
