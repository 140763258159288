import React from 'react';
import './OrangeBorderButton.scss';
import { Link } from 'react-router-dom';

const OrangeBorderButton = ({
children,
onClick = null,
className = '',
classNameForMain = '',
width = 115,
height = 33,
link = null,
disabled
}) => (
    <div className={classNameForMain ? classNameForMain : ''}>
        <Link to={link} className={`orange-border-button-link`}>
            <button
                disabled={disabled}
                className={`orange-border-button ${className}`}
                onClick={onClick}
                style={{ width: `${width}px`, height: `${height}px` }}
            >
                {children}
            </button>
        </Link>
    </div>
);

export default OrangeBorderButton;
