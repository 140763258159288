import React from "react";


import './FilterSideBar.scss'



const FillterSideBar = ({
                            sellPools,
                            buyPools,
                            checked,
                            setChecked,
                            setSearchCollection,
                            selectedFilterCollection,
                            setSelectedFilterCollection,
                            setSelectedFilterSchema,
                            setSelectedPoolType,
                            selectedPoolType
}) => {


    const handleCheckboxClick = (type) => {
        if (type === selectedPoolType) {
            setSelectedPoolType('');
        } else {
            setSelectedPoolType(type);
        }
    }

    const handleSelectSchema = (schema) => {
        setSelectedFilterSchema(schema)

        sellPools.concat(buyPools).find((item) => {
            if (item.schemas.includes(...schema))
                setSelectedFilterCollection(item.NFTs_collection.collection_name)
        })

    }

    function handleSelectedFilterCollection(event) {
        setSelectedFilterCollection(event.target.value)
        setSelectedFilterSchema([])
    }

    return (
        <div className="side-bar">
            <div className="collections">
                <p>Collections</p>
                <select value={selectedFilterCollection} onChange={(event) => handleSelectedFilterCollection(event)}>
                    <option value="all">All</option>
                    {sellPools && [...new Set(sellPools.concat(buyPools)
                        .map(item => item.NFTs_collection.collection_name))]
                        .map((collectionName) => (
                            <option key={collectionName} value={collectionName}>{collectionName}</option>
                        ))
                    }
                </select>
            </div>
            <div className="schemas">
                <p>Schemas</p>
                <div className="schemas-list">
                    {sellPools.concat(buyPools)
                        .filter((item) => {
                            if (selectedFilterCollection === 'all') {
                                return true;
                            }
                            return item.NFTs_collection.collection_name === selectedFilterCollection;
                        })
                        .reduce((uniqueSchemas, item) => {
                            if (!uniqueSchemas.includes(item.schemas)) {
                                uniqueSchemas.push(item.schemas);
                            }
                            return uniqueSchemas;
                        }, [])
                        .map((schema, index) => (
                            <p key={index} onClick={() => handleSelectSchema(schema)}>
                                {schema}
                            </p>
                        ))}
                </div>
            </div>
            <div className="tool-type">
                <p>Tool type</p>
                <div className="tool-type-block">
                    <p>NFT</p>
                    <input type="checkbox" checked={selectedPoolType === 'nft'} onChange={() => handleCheckboxClick('nft')} />
                </div>
                <div className="tool-type-block">
                    <p>Token</p>
                    <input type="checkbox" checked={selectedPoolType === 'token'} onChange={() => handleCheckboxClick('token')} />
                </div>
                <div className="tool-type-block">
                    <p>Trade</p>
                    <input type="checkbox" checked={selectedPoolType === 'trade'} onChange={() => handleCheckboxClick('trade')} />
                </div>
            </div>
        </div>
    );
};

export default FillterSideBar;
