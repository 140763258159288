import React, {useContext, useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import OrangeBorderButton from "../../OrangeBorderButton/OrangeBorderButton";
import {buyPoolInit, sellPoolInit, tradePoolInit} from "../../../services/wax.services";
import {UALContext} from "ual-reactjs-renderer";
import {BOTH, BUY, SELL} from "../../../constants/pool.constants";
import {toast} from "react-toastify";
import {Checkbox} from "@mui/material";
import {toastOptions} from "../../../helpers/toast.helper";


function FourthStep({
currentStep,
delta,
schema,
templates,
collection,
startPrice,
 waxTokenConfig,
exponDelta,
selectedOption,
selectedItemFromSell,
priceTotal,
priceExponTotal,
totalPrice,
selectedCollectionForSellPool,
selectedSchemaForSellPool,
myNFTs,
totalTradeToCost,
totalTradeToEarn,
feesTrade
}) {

    const navigate = useNavigate();
    const {activeUser} = useContext(UALContext);

    const [totalCalculatetoRecieve, setTotalCalculatetoRecieve] = useState(0)
    const [selectedNftsForDepositTrade, setSelectedNftsForDepositTrade] = useState([])

    useEffect(() => {
        const totalGivePrice = startPrice + delta * selectedItemFromSell.length;
        setTotalCalculatetoRecieve(totalGivePrice)
    }, [])

    const handlerClickItem = (item) => {
        const index = selectedNftsForDepositTrade.findIndex((i) => i.asset_id === item.asset_id);
        if (index === -1) {
            setSelectedNftsForDepositTrade([...selectedNftsForDepositTrade, item]);
        } else {
            setSelectedNftsForDepositTrade(selectedNftsForDepositTrade.filter((i) => i.asset_id !== item.asset_id));
        }
    };


    const handlerCreateBuyPool = () => {
        buyPoolInit(activeUser, startPrice, collection?.collection_name, schema , templates , delta, exponDelta, waxTokenConfig, totalPrice)
            .then(() => {
                toast.success('Pool created', toastOptions);

                navigate('/pool')
            })
            .catch((error) => {
                const errorMessage = error.message.replace('assertion failure with message: ', '');
                toast.success(errorMessage, toastOptions);
                console.log(error);
            })
    }

    const handlerCreateSellPool = () => {
        sellPoolInit(activeUser, startPrice, collection, templates = [], delta, exponDelta, waxTokenConfig, selectedItemFromSell, selectedCollectionForSellPool.collection_name, selectedSchemaForSellPool)
            .then(() => {

                toast.success('Pool created', toastOptions);

                navigate('/pool')

            })
            .catch((error) => {
                const errorMessage = error.message.replace('assertion failure with message: ', '');
                toast.success(errorMessage, toastOptions);
                console.log(error);
            })
    }


    const handlerCreateTradePool = () => {
        tradePoolInit(activeUser, startPrice, collection, templates = [], delta, exponDelta, waxTokenConfig, selectedNftsForDepositTrade, schema, feesTrade, totalTradeToEarn)
            .then(() => {

                toast.success('Pool created', toastOptions);

                navigate('/pool')

            })
            .catch((error) => {
                const errorMessage = error.message.replace('assertion failure with message: ', '');
                toast.success(errorMessage, toastOptions);
                console.log(error);
            })
    }

    return (
            <div className={`create-pool-stepper-fourth ${currentStep !== 4 ? 'hidden' : ''}`}>
                {selectedOption === BUY &&
                    <>
                        <h5>Your Pool Details</h5>
                        <div className="finish-text">
                            <p>You are depositing <span>{totalPrice} WAX</span> to buy up to <span>{collection?.collection_name}</span> NFTs</p>
                            <p>Your pool will have a starting price of <span>{startPrice} WAX</span></p>
                            <p>Each time your pool buys an NFT, your price will adjust down by <span>{delta} WAX</span></p>
                        </div>
                        <OrangeBorderButton onClick={() => handlerCreateBuyPool()}>Create Pool</OrangeBorderButton>
                    </>
                }

                {selectedOption === SELL &&
                    <>
                        <div className={`create-pool-stepper-fourth ${currentStep !== 4 ? 'hidden' : ''}`}>
                            <h5>Your Pool Details</h5>
                            <div className="finish-text">
                                <p>You are depositing <span>{selectedItemFromSell.length} {collection} NFTs</span>  to sell up to <span>{totalCalculatetoRecieve}  WAX</span></p>
                                <p>Your pool will have a starting price of <span>{startPrice} WAX</span></p>
                                <p>Each time your pool sells an NFT, your price will adjust up by <span>{delta !== 0 ? `${delta} WAX` : `${exponDelta}%`}</span></p>
                            </div>
                            <div className="selected-nfts-list">
                                {selectedItemFromSell.map((item) =>
                                    <div className="selected-nfts-list_item">
                                        <div className="image">
                                            {item.data.img?.startsWith('Qm') ? (
                                                <img src={`https://atomichub-ipfs.com/ipfs/${item.data.img}`} alt=""/>
                                            ) : (
                                                <img src={item.data.img} alt=""/>
                                            )}
                                        </div>
                                        <div className="collection-block">
                                            <p>{item.collection.collection_name}</p>
                                        </div>
                                        <div className="asset-id">
                                            <p>#{item.asset_id}</p>
                                        </div>
                                    </div>
                                    )
                                }
                            </div>
                            <OrangeBorderButton onClick={() => handlerCreateSellPool()}>Create Pool</OrangeBorderButton>
                        </div>
                    </>
                }

                {selectedOption === BOTH &&
                    <>
                        <h5>Your Pool Details</h5>
                        <div className="finish-text bottom-border">
                           <p>You are depositing <span>{totalTradeToEarn.toFixed(4)} WAX</span> to buy up to <span>{selectedCollectionForSellPool?.collection_name}</span> NFTs</p>
                           <p>You are depositing <span>{selectedNftsForDepositTrade.length} {selectedCollectionForSellPool?.collection_name}</span></p>
                           <p>Your pool will have a starting price of <span>{startPrice} WAX</span></p>
                           <p>Each time your pool buys an NFT, your price will adjust down by <span>{delta !== 0 ? `${delta} WAX` : `${exponDelta}%`}</span></p>
                           <p>Each time your pool sells an NFT, your price will adjust up by N <span>{delta !== 0 ? `${delta} WAX` : `${exponDelta}%`}</span></p>
                        </div>
                        <div className="my-nfts-container">
                            <div className="my-nfts-container_title">
                                <h5>Deposit {selectedNftsForDepositTrade.length} NFTs</h5>
                            </div>
                            <div className="my-nfts-container_list">
                                {myNFTs && myNFTs
                                    .filter((item) => item.collection.collection_name === selectedCollectionForSellPool?.collection_name)
                                    .map((item, index) => {
                                        const isChecked = selectedNftsForDepositTrade.some((i) => i.asset_id === item.asset_id);
                                        return (
                                            <div className="my-nft-item" key={index} onClick={() => handlerClickItem(item)}>
                                                <div className="checked">
                                                    <Checkbox checked={isChecked} color="success" />
                                                </div>
                                                <div className="image">
                                                    {item.data.img?.startsWith('Qm') ? (
                                                        <img src={`https://atomichub-ipfs.com/ipfs/${item.data.img}`} alt=""/>
                                                    ) : (
                                                        <img src={item.data.img} alt=""/>
                                                    )}
                                                </div>
                                                <div className="collection-block">
                                                    <p>{item.collection.collection_name}</p>
                                                </div>
                                                <div className="asset-id">
                                                    <p>#{item.asset_id}</p>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                        <OrangeBorderButton onClick={() => handlerCreateTradePool()}>Create Pool</OrangeBorderButton>
                    </>
                }
            </div>


    )
}

export default FourthStep;
