import React, { useContext, useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { UALContext } from 'ual-reactjs-renderer';

import {
    depositTokenToTrade, getMyNFTs,
    getTradePoolsByCollections,
    withdrawNftsFromTrade,
    withdrowTokenFromTrade
} from '../../services/wax.services';

import SliderS from "../../components/Slider/Slider";

import WaxLogo from "../../images/wax_logo2.png";
import ArrowIcon from "../../images/arrow2.png";
import ViewIcon from "../../images/view.png"
import LogoIcon from "../../images/logo@2x.png"
import LoaderData from "../../components/LoaderData/LoaderData";
import {getDataFromAtomicApi, shortenText} from "../../helpers";

import {LinearDiagramForTrade} from "../../components/LinearDiagramForTrade/LinearDiagramForTrade";
import {ExponentialDiagramForTrade} from "../../components/ExponentialDiagramForTrade/ExponentialDiagramForTrade";

import {toast} from "react-toastify";
import RodalDepositTradePool from "../../components/Modal/RodalDepositTradePool/RodalDepositTradePool";
import {toastOptions} from "../../helpers/toast.helper";

import './PoolPageTrade.scss';



const PoolPageTrade = () => {
    const { name, id } = useParams();
    const navigate = useNavigate();
    const { activeUser } = useContext(UALContext);

    const [currentPool, setCurrentPool] = useState(null);
    const [delta, setDelta] = useState(0);

    const [isWithdrow, setIsWithdrow] = useState(false);

    const [isEditingWithdrawNFT, setIsEditingWithdrawNFT] = useState(false);

    const [selectedItemToWithdraw, setSelectedItemToWithdraw] = useState([])

    const [sliderValue, setSliderValue] = useState(0)
    const [sliderValueForSell, setSliderValueForSell] = useState(0)

    const [price, setPrice] = useState(0)
    const [priceExpon, setPriceExpon] = useState(0)

    const [isEditingDeposite, setIsEditingDeposite] = useState(false);
    const [isEditingDepositeNFT, setIsEditingDepositeNFT] = useState(false);

    const [visible, setVisible] = useState(false);


    const [currentDelta, setCurrentDelta] = useState('Linear')
    const [NFTonSale, setNFTonSale] = useState([])

    const [collectionImg, setCollectionImg] = useState('')

    const [amountDeposite, setAmountDeposite] = useState(currentPool?.pool_asset);

    const [willCost, setWillCost] = useState([])
    const [willEarn, setWillEarn] = useState([])

    const [willCostTotal, setWillCostTotal] = useState(0)
    const [willEarnTotal, setWillEarnTotal] = useState(0)

    const [amountNFt,setAmountNft] = useState([])

    const [myNFTs, setMyNFTs] = useState([])
    const [selectedNftToDeposit, setSelectedNftToDeposit] = useState([])

    const handleSelectItem = (item) => {
        setSelectedNftToDeposit((prevSelectedNftToDeposit) => {
            if (selectedNftToDeposit.includes(item)) {
                return prevSelectedNftToDeposit.filter((selectedItem) => selectedItem !== item);
            } else {
                return [...prevSelectedNftToDeposit, item];
            }
        });
    };

    const handleDeposit = (amount) => {
        depositTokenToTrade({ activeUser, amount: amount, pool_id: id })
            .then(() => {
                toast.success('Deposited successfully', toastOptions);

                setTimeout(async () => {
                    const data = await getTradePoolsByCollections();
                    const pool = data.find((item) => item.pool_id === parseInt(id));

                    setCurrentPool(pool);
                }, 1000);
            })
            .catch((error) => {
                const errorMessage = error.message.replace('assertion failure with message: ', '');
                toast.error(errorMessage, toastOptions);
                console.log(error);
            });
    };

    const handleWithdraw = (amount) => {
        withdrowTokenFromTrade({ activeUser, amount: amount, pool_id: id })
            .then(() => {
                toast.success('Withdrawn successfully', toastOptions);

                setTimeout(async () => {
                    const data = await getTradePoolsByCollections();
                    const pool = data.find((item) => item.pool_id === parseInt(id));

                    setCurrentPool(pool);
                }, 1000);
            })
            .catch((error) => {
                const errorMessage = error.message.replace('assertion failure with message: ', '');
                toast.error(errorMessage, toastOptions);
                console.log(error);
            });
    };

    const handleToWithdraw = (item) => {
        const index = selectedItemToWithdraw.indexOf(item);
        if (index === -1) {
            setSelectedItemToWithdraw([...selectedItemToWithdraw, item]);
        } else {
            const updatedItems = [...selectedItemToWithdraw];
            updatedItems.splice(index, 1);
            setSelectedItemToWithdraw(updatedItems);
        }
    };

    const hide = () => {
        setVisible(false);
    };

    const toggleEditingToken = (type) => {
        if (type === 'deposit-token') {
            setIsEditingDeposite(!isEditingDeposite);
        }

        if (type === 'withdraw-token') {
            setIsWithdrow(!isWithdrow);
        }
    };

    const toggleEditing = (type) => {
        if (type === 'deposit') {
            setVisible(true);
            setIsEditingDepositeNFT(!isEditingDepositeNFT);
        }

        if (type === 'withdraw') {
            setIsEditingWithdrawNFT(!isEditingWithdrawNFT);
        }
    };

    const handleSaveDepToken = () => {
        if (!amountDeposite) {
            setIsEditingDeposite(false);
            setIsWithdrow(false);
            return;
        } else {
            setAmountDeposite(null);
        }

        if (isEditingDeposite) {
            handleDeposit(amountDeposite);
            setIsEditingDeposite(false);
        }

        if (isWithdrow) {
            handleWithdraw(amountDeposite);
            setIsWithdrow(false);
        }
    };

    const handleSaveWithdrawNft = async () => {
        try {
            if (isEditingDepositeNFT) {
                setIsEditingDepositeNFT(false);
            }

            if (isEditingWithdrawNFT && selectedItemToWithdraw.length) {
                await withdrawNftsFromTrade({ activeUser, selectedItemToWithdraw, id });
                toast.success('Success', toastOptions);

                setTimeout(async () => {
                    const data = await getTradePoolsByCollections();
                    const pool = data.find((item) => item.pool_id === parseInt(id));

                    setCurrentPool(pool);
                    setNFTonSale(pool.pool_nfts);

                    setSelectedItemToWithdraw([])
                }, 1000)

            }
        } catch (error) {
            const errorMessage = error.message.replace('assertion failure with message: ', '');
            toast.error(errorMessage, toastOptions);
            console.error(error);
        } finally {
            setIsEditingWithdrawNFT(false);
        }
    };

    const handleClickBack = () => {
        navigate(`/collections/${name}`);
    };

    const handleClickDirect = () => {
        navigate(`/collections/${name}/swap/trade/${id}`);
    };

    useEffect(() => {
        const fetchMyNFTs = async () => {
            try {
                const data = await getMyNFTs(activeUser?.accountName, name);
                setMyNFTs(data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchMyNFTs();
    }, [activeUser]);

    useEffect(() => {
        const fetchPool = async () => {
            await getTradePoolsByCollections()
                .then((data) => {
                    const pool = data.find((item) => item.pool_id === parseInt(id));

                    setCurrentPool(pool);
                    setNFTonSale(pool.pool_nfts)

                    if (pool.linear_delta === 0 && pool.exponential_delta === "0.00000000000000000") {
                        setCurrentDelta('Linear')
                    } else if (pool.linear_delta !== 0) {
                        setCurrentDelta('Linear')
                    } else {
                        setCurrentDelta('Exponential')
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        };

        fetchPool();
    }, [id]);


    useEffect(() => {
        if (sliderValue === 0) {
            setPriceExpon(0);
        } else if (sliderValue === 1) {
            setPriceExpon(Number(currentPool?.floor_price.split(' ')[0]));
        } else {
            let price = Number(currentPool?.floor_price.split(' ')[0]);
            let totalPrice = price;
            for (let i = 2; i <= Number(sliderValue); i++) {
                price += price * (delta / 100);
                totalPrice += price;
            }
            setPriceExpon(totalPrice);
        }
    }, [currentPool, sliderValue, delta]);

    useEffect(() => {
        let price = 0;
        let currentDelta = Number(delta);

        if (sliderValue > 0) {
            price = Number(currentPool?.floor_price.split(' ')[0]);
            const calculatePrice = (deltaSign) => {
                for (let i = 2; i <= Number(sliderValue); i++) {
                    price += Number(currentPool?.floor_price.split(' ')[0]) + deltaSign * currentDelta;
                    currentDelta += Number(delta);
                }
                setPrice(price);
            };
            calculatePrice(1);
        } else {
            setPrice(0);
        }
    }, [currentPool, sliderValue, delta]);

    useEffect(() => {
        if (!currentPool) return;
        if (currentPool.linear_delta > 0) {
            setDelta(currentPool.linear_delta / Math.pow(10, 8));
        } else if (currentPool.exponential_delta !== '0.00000000000000000') {
            setDelta(parseFloat(currentPool.exponential_delta));
        } else {
            setDelta(0);
        }
    }, [currentPool]);

    useEffect(async () => {
        const data = await getDataFromAtomicApi(`collections/${currentPool?.NFTs_collection?.collection_name}`);
        setCollectionImg(data.img);
    }, [currentPool]);

    useEffect(() => {
        let sum = sliderValueForSell === 0 ? 0 : willEarn?.slice(0, sliderValueForSell).reduce((acc, val) => val + acc, 0);
        setWillEarnTotal(sum);
    }, [willEarn, sliderValueForSell]);

    useEffect(() => {
        if (willCost) {
            let sum = sliderValue === 0 ? 0 : willCost.slice(-sliderValue).reduce((acc, val) => acc + val, 0);
            setWillCostTotal(sum);
        }
    }, [willCost, sliderValue]);

    useEffect(() => {
        if (currentPool) {
            let pool_asset = currentPool.pool_asset.split(' ')[0];
            let floor_price = currentPool.floor_price.split(' ')[0];
            let result = pool_asset / floor_price;

            // Get integer number and difference
            let integerResult = Math.floor(result);

            setAmountNft(integerResult);
        }
    }, [currentPool]);


    return (
        <div className={'pool-page-section'}>
            <div className="pool-page-section_title">
                <div className="name-collection">
                    <img src={`https://atomichub-ipfs.com/ipfs/${collectionImg}`} alt=""/>
                    <p>{name}</p>
                </div>
                <div className="arrow-back">
                    <img src={ArrowIcon} alt=""/>
                </div>
                <div className="arrow-ahead">
                    <img src={ArrowIcon} alt=""/>
                </div>
                <div className="wax-title">
                    <img src={WaxLogo} alt=""/>
                    <p>WAX</p>
                </div>
            </div>


            <div className="block">
                <div className="action-buttons">
                    <div className="view-all" onClick={handleClickBack}>
                        <div className="view-all-fst">
                            <img src={ViewIcon} alt=""/>
                            <p>View All</p>
                        </div>
                        <div className="view-all-snd">
                            <img src={`https://atomichub-ipfs.com/ipfs/${collectionImg}`} alt=""/>
                            <p>{name}</p>
                        </div>
                    </div>

                    {activeUser && currentPool && currentPool.pool_owner && activeUser.accountName !== currentPool.pool_owner && (
                        <div className="direct-trade-swap" onClick={handleClickDirect} >
                            <img src={LogoIcon} alt=""/>
                            <p>Swap</p>
                        </div>
                    )}
                </div>

                <div className="assets-block-trade trade">
                    <div className="assets">
                        <div className="assets_title">
                            <h5>Assets</h5>
                        </div>

                        <div className="deposit-field">
                            <div className="wrapper">
                                <div className="deposit-field-tokens">
                                    <p className="little-title">Tokens</p>
                                    {isEditingDeposite || isWithdrow ? (
                                        <input
                                            type="number"
                                            value={amountDeposite}
                                            onChange={(e) => setAmountDeposite(e.target.value)}
                                        />
                                    ) : (
                                        <p className="amount-token">{currentPool?.pool_asset}</p>
                                    )}
                                </div>
                                {activeUser && currentPool && currentPool.pool_owner && activeUser.accountName === currentPool.pool_owner && (
                                    <div className="deposit-withdraw-btn">
                                        {isEditingDeposite ? (
                                            <button className="save-btn" onClick={handleSaveDepToken}>
                                                Save
                                            </button>
                                        ) : (
                                            <button className="deposit-btn" onClick={() => toggleEditingToken('deposit-token')}>
                                                Deposit
                                            </button>
                                        )}
                                        {isWithdrow ? (
                                            <button className="save-btn" onClick={handleSaveDepToken}>
                                                Save
                                            </button>
                                        ) : (
                                            <button className="deposit-btn" onClick={() => toggleEditingToken('withdraw-token')}>
                                                Withdrow
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="deposit-field">
                            <div className="wrapper">
                                <div className="nfts">
                                    <p>NFTs</p>
                                    <p>{currentPool?.pool_nfts.length === 0 ? 'Empty pool' : currentPool?.pool_nfts.length}</p>
                                </div>
                                {activeUser && currentPool && currentPool.pool_owner && activeUser.accountName === currentPool.pool_owner && (
                                    <div className="deposit-withdraw-btn">
                                        <button className="deposit-btn" onClick={() => toggleEditing('deposit')}>
                                            Deposit
                                        </button>
                                        {isEditingWithdrawNFT ? (
                                            <button className="save-btn" onClick={handleSaveWithdrawNft}>
                                                Save
                                            </button>
                                        ) : (
                                            <button className="withdraw-btn" onClick={() => toggleEditing('withdraw')}>
                                                Withdraw
                                            </button>
                                        )}

                                    </div>
                                )}
                            </div>
                        </div>
                        {!isEditingWithdrawNFT ?
                            <div className="nfts-field">
                                {!NFTonSale.length ?
                                    <LoaderData />
                                    :
                                    <>
                                        <div className="nfts-field-list">
                                            {NFTonSale && NFTonSale.map((item) => {
                                                const imgUrl = item?.data?.img?.startsWith('Qm')
                                                    ? `https://atomichub-ipfs.com/ipfs/${item?.data?.img}`
                                                    : item?.data?.img;

                                                return (
                                                    <div className="nfts-field-list_item" key={item?.asset_id}>
                                                        <div className="nfts-field-list_item-image">
                                                            <img src={imgUrl} alt="" />
                                                        </div>
                                                        <div className="nfts-field-list_item-info">
                                                            <p>#{item?.asset_id}</p>
                                                            <h6>{shortenText(item?.data?.name, 15)}</h6>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            {/*{!allElementsLoaded &&*/}
                                            {/*    <OrangeBorderButton onClick={loadMore}>Load more</OrangeBorderButton>*/}
                                            {/*}*/}
                                        </div>
                                    </>
                                }

                            </div>
                            :
                            <div className="nfts-field withdraw">
                                {!NFTonSale.length ?
                                    <LoaderData />
                                    :
                                    <>
                                        <div className="nfts-field-list">
                                            {NFTonSale && NFTonSale.map((item) => {
                                                const imgUrl = item?.data?.img?.startsWith('Qm')
                                                    ? `https://atomichub-ipfs.com/ipfs/${item?.data?.img}`
                                                    : item?.data?.img;

                                                return (
                                                    <div
                                                        className={`nfts-field-list_item withdraw ${selectedItemToWithdraw.includes(item?.asset_id) ? 'active' : ''}`}
                                                        key={item?.asset_id}
                                                        onClick={() => handleToWithdraw(item?.asset_id)}
                                                    >
                                                        <div className="nfts-field-list_item-image">
                                                            <img src={imgUrl} alt="" />
                                                        </div>
                                                        <div className="nfts-field-list_item-info">
                                                            <p>#{item?.asset_id}</p>
                                                            <h6>{shortenText(item?.data?.name)}</h6>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            {/*{!allElementsLoaded &&*/}
                                            {/*    <OrangeBorderButton onClick={loadMore}>Load more</OrangeBorderButton>*/}
                                            {/*}*/}
                                        </div>
                                    </>
                                }

                            </div>
                        }
                    </div>
                    <div className="about">
                        <div className="pricing">
                            {/*{activeUser && currentPool && currentPool.pool_owner && activeUser.accountName === currentPool.pool_owner &&*/}
                            {/*    <div className="edit">*/}
                            {/*        <button onClick={handleEditClick}>Edit</button>*/}
                            {/*    </div>*/}
                            {/*}*/}

                            <div className="pricing_title">
                                <h5>Pricing</h5>
                            </div>
                            <div className="info">
                                <div className="current-price">
                                    <div className="wrapper">
                                        <div className="little-title">
                                            <p>Current Price</p>
                                        </div>
                                        <p className={'floor_price'}>{currentPool?.floor_price}</p>
                                    </div>
                                </div>
                                <div className="delta">
                                    <div className="wrapper">
                                        <div className="little-title">
                                            <p>Delta: {currentDelta}</p>
                                        </div>
                                        <>
                                            {currentDelta === 'Linear' &&
                                                <div className={'delta-number'}>
                                                    <p>{delta} WAX</p>
                                                </div>
                                            }
                                            {currentDelta === 'Exponential' &&
                                                <div className={'delta-number'}>
                                                    <p>{delta}%</p>
                                                </div>
                                            }
                                        </>
                                    </div>
                                </div>
                                <div className="current-fees">
                                    <div className="wrapper">
                                        <div className="little-title">
                                            <p>Fees</p>
                                        </div>
                                        <p className={'floor_price'}>{Math.floor(Number(currentPool?.pool_fees))}%</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="about-owner">
                            <div className="about-owner_title">
                                <h5>About</h5>
                            </div>
                            <div className="about-owner_info">
                                <p>Pool owner:</p>
                                <div className="about-owner_info-address">
                                    <p>{currentPool?.pool_owner}</p>
                                </div>
                            </div>
                            <div className="about-owner_pool-info">
                                <div>This pool holds {currentPool?.NFTs_collection?.collection_name} and buys <img className={'mini-wax-logo'} src={WaxLogo} alt=""/> WAX</div>
                                <div>Right now this pool costs <span>{currentPool?.floor_price}</span></div>
                                <div>Each time this pool sells an NFT, the price will be increased by {currentDelta === 'Linear' && <span>{delta} WAX</span>} {currentDelta === 'Exponential' && <span>{delta.toFixed(2)}%</span>} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="diagram-block">
                <div className="diagram-block_title">
                    <h5>Price Graph</h5>
                </div>
                <div className="amount-sliders">
                    <div className="amount-slider">
                        <h4>Buying {sliderValue} NFTs</h4>
                        <SliderS setSliderValue={setSliderValue} slideValue={sliderValue}  amount={amountNFt} />
                        {currentDelta === 'Linear' &&
                            <p>will cost this pool {willCostTotal.toFixed(8)} WAX</p>
                        }
                        {currentDelta === 'Exponential' &&
                            <p>will cost this pool {willCostTotal.toFixed(8)} WAX</p>
                        }
                    </div>
                    <div className="amount-slider">
                        <h4>Selling {sliderValueForSell} NFTs</h4>
                        <SliderS setSliderValue={setSliderValueForSell} slideValue={sliderValueForSell}  amount={currentPool?.pool_nfts.length} />
                        {currentDelta === 'Linear' &&
                            <p>will earn this pool {willEarnTotal.toFixed(8)} WAX</p>
                        }
                        {currentDelta === 'Exponential' &&
                            <p>will earn this pool {willEarnTotal.toFixed(8)} WAX</p>
                        }
                    </div>
                </div>
                <div className="diagram-block-content">
                    {currentDelta === 'Linear' &&
                        <LinearDiagramForTrade
                            startPrice={currentPool?.floor_price.split(' ')[0]}
                            delta={delta} amountToBuy={amountNFt}
                            amountToSell={currentPool?.pool_nfts.length}
                            fee={currentPool?.pool_fees}
                            setWillCost={setWillCost}
                            setWillEarn={setWillEarn}
                        />
                    }
                    {currentDelta === 'Exponential' &&
                        <ExponentialDiagramForTrade
                            startPrice={currentPool?.floor_price.split(' ')[0]}
                            delta={delta} amountToBuy={amountNFt}
                            amountToSell={currentPool?.pool_nfts.length}
                            fee={currentPool?.pool_fees}
                            setWillCost={setWillCost}
                            setWillEarn={setWillEarn}
                        />
                    }
                </div>
            </div>

            <RodalDepositTradePool
                visible={visible}
                hide={hide}
                myNFTs={myNFTs}
                setMyNFTs={setMyNFTs}
                handleSelectItem={handleSelectItem}
                selectedNftToDeposit={selectedNftToDeposit}
                setSelectedNftToDeposit={setSelectedNftToDeposit}
                id={id}
                user={activeUser}
                name={name}
                setCurrentPool={setCurrentPool}
                setNFTonSale={setNFTonSale}
                setSelectedItemToWithdraw={setSelectedItemToWithdraw}
            />
        </div>
    )
};

export default PoolPageTrade;
