import React from 'react'
import WaxIcon from "../../../images/wax_logo.png";

import OrangeBorderButton from "../../OrangeBorderButton/OrangeBorderButton";

import {
    BUY,
    SELL,
    BOTH
} from '../../../constants/pool.constants'


function SecondStep({currentStep, selectedOption, selectedCollection, show, selectedCollectionForSellPool}) {


    return (
        <div className={`create-pool-stepper-second ${currentStep !== 2 ? 'hidden' : ''}`}>
            {selectedOption === BUY &&
                <div className={'create-pool-stepper-second_wrapper'}>
                    <div className="left">
                        <p>I want to …</p>
                        <p>Deposit</p>
                        <OrangeBorderButton width={180} height={44}><img className={'wax-logo'} src={WaxIcon} alt=""/>Wax</OrangeBorderButton>
                    </div>
                    <div className="right">
                        <p>and ...</p>
                        <p>Receive</p>
                        <OrangeBorderButton onClick={show} width={180} height={44}>{selectedCollection ?
                            <>
                                {selectedCollection.data.img && selectedCollection.data.img?.startsWith('Qm') ? (
                                    <img src={`https://atomichub-ipfs.com/ipfs/${selectedCollection.data.img}`} alt=""/>
                                ) : (
                                    <img src={selectedCollection.data.img} alt=""/>
                                )}
                                {selectedCollection?.collection_name }
                            </>
                            :
                            'Select NFT'} <span className={'arrow'}> > </span></OrangeBorderButton>
                    </div>
                </div>
            }
            {selectedOption === SELL &&
                <div className={'create-pool-stepper-third_wrapper'}>
                    <div className="left">
                        <p>I want to …</p>
                        <p>Deposit</p>

                        <OrangeBorderButton onClick={show} width={180} height={44}>{selectedCollectionForSellPool ?
                            <>
                                {selectedCollectionForSellPool?.img && selectedCollectionForSellPool?.img.startsWith('Qm') ? (
                                    <img src={`https://atomichub-ipfs.com/ipfs/${selectedCollectionForSellPool?.img}`} alt=""/>
                                ) : (
                                    <img src={selectedCollectionForSellPool?.img} alt=""/>
                                )}
                                {selectedCollectionForSellPool?.collection_name }
                            </>
                            :
                            'Select NFT'} <span className={'arrow'}> > </span></OrangeBorderButton>
                    </div>
                    <div className="right">
                        <p>and ...</p>
                        <p>Receive</p>
                        <OrangeBorderButton width={180} height={44}><img className={'wax-logo'} src={WaxIcon} alt=""/>Wax</OrangeBorderButton>
                    </div>
                </div>
            }
            {selectedOption === BOTH &&
                <div className={'create-pool-stepper-second_wrapper'}>
                    <div className="left">
                        <p>I want to …</p>
                        <p>Deposit</p>
                        <OrangeBorderButton width={180} height={44}><img className={'wax-logo'} src={WaxIcon} alt=""/>Wax</OrangeBorderButton>
                    </div>
                    <div className="right">
                        <p>I want to …</p>
                        <p>Deposit</p>
                        <OrangeBorderButton onClick={show} width={180} height={44}>{selectedCollection ?
                            <>
                                {selectedCollection.data.img && selectedCollection.data.img?.startsWith('Qm') ? (
                                    <img src={`https://atomichub-ipfs.com/ipfs/${selectedCollection.data.img}`} alt=""/>
                                ) : (
                                    <img src={selectedCollection.data.img} alt=""/>
                                )}
                                {selectedCollection?.collection_name }
                            </>
                            :
                            'Select NFT'} <span className={'arrow'}> > </span></OrangeBorderButton>
                    </div>
                </div>
            }
        </div>
    )
}

export default SecondStep;
