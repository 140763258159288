import React, {useEffect, useState} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import {
    BUY,
    SELL,
    BOTH
} from '../../constants/pool.constants'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
);


export function LinearDiagram({selectedOption, startPrice, delta, amount, slideValue}) {
    const [dataDiagram, setDataDiagram] = useState([])
    const [labels, setLabels] = useState([])

    useEffect(() => {
        setDataDiagram([]);
        function LinearCurveForBuy() {
            let price = startPrice * 100;
            delta *= 100;
            const newData = [];
            for (let i = 1; i <= amount; i++) {
                newData.push(price / 100);
                price -= delta;
            }
            setDataDiagram(newData);
        }

        function LinearCurveForSell() {
            let price = startPrice * 100;
            delta *= 100;
            const newData = [];
            for (let i = 1; i <= amount; i++) {
                newData.push(price / 100);
                price += delta;
            }
            setDataDiagram(newData);
        }

        if (selectedOption === BUY)
            LinearCurveForBuy();

        if (selectedOption === SELL)
            LinearCurveForSell();

    }, [startPrice, delta, amount, setDataDiagram]);


    const generatePointBackgroundColors = () => {
        return Array.from({ length: amount }, (_, i) =>
            i < slideValue ? "#E65634" : "#FFFFFF"
        );
    }


    useEffect(() => {
        const newLabels = Array.from({ length: amount }, (_, i) => (i + 1).toString());
        setLabels(newLabels);
    }, [amount, setLabels]);


    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: null,
            },
        },
        scales: {
            y: {
                ticks: {color: '#FFFFFF', beginAtZero: true},
                grid: {
                    display: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    color: '#8E8E8E'
                }
            },
            x: {
                ticks: {color: '#FFFFFF', beginAtZero: true},
                grid: {
                    display: true,
                    drawOnChartArea: true,
                    drawTicks: true,
                    color: '#2B282C'
                }
            }
        },
    };


    const data = {
        labels,
        datasets: [
            {
                data: dataDiagram,
                borderColor: 'rgb(255,255,255)',
                backgroundColor: 'rgba(77,50,42,0.54)',
                pointBorderWidth: 7,
                pointBorderColor: generatePointBackgroundColors,
                pointBackgroundColor: '#E65634',
                fill: true,
                color: '#FFFFFF',
            },
        ],
    };

    return <Line width={927} height={241} options={options} data={data} />;
}
